import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  GenericScalar: any;
  JSONString: any;
  UUID: any;
};

/** An enumeration. */
export enum AccessMode {
  Read = 'read',
  Write = 'write'
}

export type Alert = Node & {
  __typename: 'Alert';
  assetKind?: Maybe<AssetKind>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventSource?: Maybe<EventSource | '%future added value'>;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  /** Numeric value of the priority */
  numericPriority?: Maybe<Scalars['Int']>;
  priority?: Maybe<Priority | '%future added value'>;
  resolved: Scalars['Boolean'];
  rule?: Maybe<AlertRule>;
  source?: Maybe<BaseAsset>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid?: Maybe<Scalars['UUID']>;
};

export type AlertConnection = {
  __typename: 'AlertConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AlertCounter = {
  __typename: 'AlertCounter';
  high?: Maybe<Scalars['Int']>;
  low?: Maybe<Scalars['Int']>;
  medium?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Alert` and its cursor. */
export type AlertEdge = {
  __typename: 'AlertEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Alert>;
};

export type AlertRule = Node & {
  __typename: 'AlertRule';
  active: Scalars['Boolean'];
  alerts: AlertConnection;
  assetKind?: Maybe<AssetKind>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  defaultPriority?: Maybe<Priority | '%future added value'>;
  definitionObject?: Maybe<Scalars['GenericScalar']>;
  description?: Maybe<Scalars['String']>;
  eventSource?: Maybe<EventSource | '%future added value'>;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  raiseAlertOnError: Scalars['Boolean'];
  ruleKind: AlertsAlertRuleRuleKindChoices | '%future added value';
  taskSet: TaskConnection;
  taskTemplate?: Maybe<TaskTemplate>;
  tasktemplateSet: TaskTemplateConnection;
  updatedAt: Scalars['DateTime'];
};


export type AlertRuleAlertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Priority>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type AlertRuleTaskSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type AlertRuleTasktemplateSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};

export type AlertRuleConnection = {
  __typename: 'AlertRuleConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertRuleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AlertRule` and its cursor. */
export type AlertRuleEdge = {
  __typename: 'AlertRuleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AlertRule>;
};

/** An enumeration. */
export enum AlertsAlertRuleRuleKindChoices {
  /** AI Based */
  AiBased = 'AI_BASED',
  /** Rule Based */
  RuleBased = 'RULE_BASED'
}

export type AppModule = Node & {
  __typename: 'AppModule';
  description?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AppModuleConnection = {
  __typename: 'AppModuleConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppModuleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppModule` and its cursor. */
export type AppModuleEdge = {
  __typename: 'AppModuleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppModule>;
};

export type AppModuleRoleConfiguration = Node & {
  __typename: 'AppModuleRoleConfiguration';
  /** The ID of the object */
  id: Scalars['ID'];
  mode?: Maybe<AccessMode | '%future added value'>;
  module: AppModule;
  role: Role;
};

export type AppModuleRoleConfigurationConnection = {
  __typename: 'AppModuleRoleConfigurationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppModuleRoleConfigurationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppModuleRoleConfiguration` and its cursor. */
export type AppModuleRoleConfigurationEdge = {
  __typename: 'AppModuleRoleConfigurationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppModuleRoleConfiguration>;
};

export type AssetDetailsLayout = Node & {
  __typename: 'AssetDetailsLayout';
  assetKind?: Maybe<AssetKind>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['GenericScalar']>;
};

export type AssetFilterValuesParams = {
  assetKindId: Scalars['ID'];
  field?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  pagination?: InputMaybe<LimitOffsetPagination>;
  search?: InputMaybe<Scalars['String']>;
};

export type AssetKind = Node & {
  __typename: 'AssetKind';
  fileIcon?: Maybe<Scalars['String']>;
  fontIcon?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  presentation?: Maybe<AssetPresentation>;
  schema?: Maybe<AssetSchema>;
  value: Scalars['String'];
};

export type AssetKindConnection = {
  __typename: 'AssetKindConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetKindEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetKind` and its cursor. */
export type AssetKindEdge = {
  __typename: 'AssetKindEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AssetKind>;
};

export type AssetPresentation = Node & {
  __typename: 'AssetPresentation';
  assetKind: AssetKind;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  table?: Maybe<Scalars['GenericScalar']>;
  updatedAt: Scalars['DateTime'];
};

export type AssetPresentationConnection = {
  __typename: 'AssetPresentationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetPresentationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetPresentation` and its cursor. */
export type AssetPresentationEdge = {
  __typename: 'AssetPresentationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AssetPresentation>;
};

export type AssetSchema = Node & {
  __typename: 'AssetSchema';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  target: AssetKind;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['GenericScalar']>;
  version: Scalars['Int'];
};


export type AssetSchemaValueArgs = {
  base?: InputMaybe<Scalars['Boolean']>;
};

export type AssetSchemaConnection = {
  __typename: 'AssetSchemaConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetSchemaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetSchema` and its cursor. */
export type AssetSchemaEdge = {
  __typename: 'AssetSchemaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AssetSchema>;
};

/** An enumeration. */
export enum AssetsEdgeRoleChoices {
  /** Driver */
  Driver = 'DRIVER',
  /** Location */
  Location = 'LOCATION',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type AssignRoleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Assigns a specific role (group) to a specific user */
export type AssignRoleMutationPayload = {
  __typename: 'AssignRoleMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type BaseAsset = Node & {
  __typename: 'BaseAsset';
  activeEdge?: Maybe<Edge>;
  assetKind: AssetKind;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['GenericScalar']>;
  datapoints: DataPointConnection;
  edges: EdgeConnection;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  /** retrieves the last datapoint for each datapoint kind */
  latestUniqueDatapoints?: Maybe<Array<Maybe<DataPoint>>>;
  organization?: Maybe<Organization>;
  /** This field includes any related information based on theexternalFields configuration on the schema */
  relatedData?: Maybe<Scalars['GenericScalar']>;
  relatesFrom: BaseAssetRelationConnection;
  relatesTo: BaseAssetRelationConnection;
  /** This field includes any data from the `data` attribute thatneeded to be rendered in a special format (i.e.: as a link) */
  renderedData?: Maybe<Scalars['GenericScalar']>;
  taskSet: TaskConnection;
  tasktemplateSet: TaskTemplateConnection;
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};


export type BaseAssetDatapointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['ID']>;
  timestamp_Range?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};


export type BaseAssetEdgesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<AssetsEdgeRoleChoices>;
  set?: InputMaybe<Scalars['ID']>;
};


export type BaseAssetRelatesFromArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type BaseAssetRelatesToArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type BaseAssetTaskSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type BaseAssetTasktemplateSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};

export type BaseAssetConnection = {
  __typename: 'BaseAssetConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BaseAssetEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BaseAsset` and its cursor. */
export type BaseAssetEdge = {
  __typename: 'BaseAssetEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BaseAsset>;
};

export type BaseAssetRelation = Node & {
  __typename: 'BaseAssetRelation';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  sourceAsset: BaseAsset;
  targetAsset: BaseAsset;
  updatedAt: Scalars['DateTime'];
};

export type BaseAssetRelationConnection = {
  __typename: 'BaseAssetRelationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BaseAssetRelationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BaseAssetRelation` and its cursor. */
export type BaseAssetRelationEdge = {
  __typename: 'BaseAssetRelationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BaseAssetRelation>;
};

export type ChartDashboard = Node & {
  __typename: 'ChartDashboard';
  cards: ChartDashboardCardConnection;
  createdAt: Scalars['DateTime'];
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  /** User-friendly name for the chart dashboard */
  name: Scalars['String'];
  snapshot: DataReportSnapshotConnection;
  updatedAt: Scalars['DateTime'];
  /**
   * If specified, the chart dashboard is assumed to be an external website and the specified
   *                     url points to the page to be rendered.
   *                     The website must allow iframe rendering.
   *                     Chart Dashboard Cards will not be used if this is specified.
   */
  url?: Maybe<Scalars['String']>;
};


export type ChartDashboardCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ChartDashboardSnapshotArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<ReportSource>;
  sourceId?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
};

export type ChartDashboardCard = Node & {
  __typename: 'ChartDashboardCard';
  charts?: Maybe<Array<Maybe<ChartDashboardCardEntry>>>;
  createdAt: Scalars['DateTime'];
  dashboard: ChartDashboard;
  /** The ID of the object */
  id: Scalars['ID'];
  position: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ChartDashboardCardConnection = {
  __typename: 'ChartDashboardCardConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChartDashboardCardEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ChartDashboardCard` and its cursor. */
export type ChartDashboardCardEdge = {
  __typename: 'ChartDashboardCardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ChartDashboardCard>;
};

export type ChartDashboardCardEntry = Node & {
  __typename: 'ChartDashboardCardEntry';
  card: ChartDashboardCard;
  configuration?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  position: Scalars['Int'];
  source: DataReportSnapshot;
};

export type ChartDashboardCardEntryConnection = {
  __typename: 'ChartDashboardCardEntryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChartDashboardCardEntryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ChartDashboardCardEntry` and its cursor. */
export type ChartDashboardCardEntryEdge = {
  __typename: 'ChartDashboardCardEntryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ChartDashboardCardEntry>;
};

export type ChartDashboardConnection = {
  __typename: 'ChartDashboardConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChartDashboardEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ChartDashboard` and its cursor. */
export type ChartDashboardEdge = {
  __typename: 'ChartDashboardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ChartDashboard>;
};

export type CreateAssetMutationInput = {
  assetKind: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: Scalars['GenericScalar'];
};

export type CreateAssetMutationPayload = {
  __typename: 'CreateAssetMutationPayload';
  asset?: Maybe<BaseAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type CreateDataReportMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  layout?: InputMaybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  params?: InputMaybe<Scalars['GenericScalar']>;
  schedulingTime?: InputMaybe<SchedulingTime | '%future added value'>;
  sort?: InputMaybe<Scalars['GenericScalar']>;
  source?: InputMaybe<ReportSource | '%future added value'>;
  sourceId: Scalars['ID'];
};

/** Creates a data report */
export type CreateDataReportMutationPayload = {
  __typename: 'CreateDataReportMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type CreateTaskCommentMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  taskId: Scalars['String'];
};

export type CreateTaskCommentMutationPayload = {
  __typename: 'CreateTaskCommentMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  taskComment?: Maybe<TaskComment>;
};

export type CreateTaskMutationInput = {
  asset?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Creates a new Task as defined on the django backend in tasks.models.Task */
export type CreateTaskMutationPayload = {
  __typename: 'CreateTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

export type DataPoint = Node & {
  __typename: 'DataPoint';
  asset: BaseAsset;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  provider: DataPointProvider;
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DataPointConnection = {
  __typename: 'DataPointConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataPointEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DataPoint` and its cursor. */
export type DataPointEdge = {
  __typename: 'DataPointEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataPoint>;
};

export type DataPointProvider = Node & {
  __typename: 'DataPointProvider';
  createdAt: Scalars['DateTime'];
  datapoints: DataPointConnection;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type DataPointProviderDatapointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['ID']>;
  timestamp_Range?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type DataReport = Node & {
  __typename: 'DataReport';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  params?: Maybe<Scalars['JSONString']>;
  schedule?: Maybe<SchedulingTime | '%future added value'>;
  snapshots: DataReportSnapshotConnection;
  sort?: Maybe<Scalars['GenericScalar']>;
  source?: Maybe<ReportSource | '%future added value'>;
  sourceId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


export type DataReportSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<ReportSource>;
  sourceId?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
};

export type DataReportConnection = {
  __typename: 'DataReportConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DataReport` and its cursor. */
export type DataReportEdge = {
  __typename: 'DataReportEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataReport>;
};

export type DataReportSnapshot = Node & {
  __typename: 'DataReportSnapshot';
  charts: ChartDashboardCardEntryConnection;
  createdAt: Scalars['DateTime'];
  dashboard?: Maybe<ChartDashboard>;
  dataStreamUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  rows: DataReportSnapshotRowConnection;
  sort?: Maybe<Scalars['GenericScalar']>;
  source?: Maybe<ReportSource | '%future added value'>;
  sourceId?: Maybe<Scalars['String']>;
  template?: Maybe<DataReport>;
  user?: Maybe<User>;
};


export type DataReportSnapshotChartsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  card?: InputMaybe<Scalars['ID']>;
  card_Dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DataReportSnapshotRowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['ID']>;
};

export type DataReportSnapshotConnection = {
  __typename: 'DataReportSnapshotConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataReportSnapshotEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DataReportSnapshot` and its cursor. */
export type DataReportSnapshotEdge = {
  __typename: 'DataReportSnapshotEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataReportSnapshot>;
};

export type DataReportSnapshotRow = Node & {
  __typename: 'DataReportSnapshotRow';
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  snapshot: DataReportSnapshot;
};

export type DataReportSnapshotRowConnection = {
  __typename: 'DataReportSnapshotRowConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataReportSnapshotRowEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DataReportSnapshotRow` and its cursor. */
export type DataReportSnapshotRowEdge = {
  __typename: 'DataReportSnapshotRowEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataReportSnapshotRow>;
};

export type DateRange = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type DeleteDataReportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteDataReportPayload = {
  __typename: 'DeleteDataReportPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type DeleteRelatedAssetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ID'];
  targetId: Scalars['ID'];
};

/** deletes a relationship between two assets (non bi-directional) */
export type DeleteRelatedAssetMutationPayload = {
  __typename: 'DeleteRelatedAssetMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  relation?: Maybe<BaseAssetRelation>;
};

export type DeleteTaskCommentMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentId: Scalars['String'];
};

export type DeleteTaskCommentMutationPayload = {
  __typename: 'DeleteTaskCommentMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  taskComment?: Maybe<TaskComment>;
};

export type DeleteTaskMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteTaskMutationPayload = {
  __typename: 'DeleteTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

export type DissociateRoleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DissociateRoleMutationPayload = {
  __typename: 'DissociateRoleMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename: 'DjangoDebugException';
  /** The class of the exception */
  excType: Scalars['String'];
  /** The message of the exception */
  message: Scalars['String'];
  /** The stack trace */
  stack: Scalars['String'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

export type Edge = Node & {
  __typename: 'Edge';
  asset: BaseAsset;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  role: AssetsEdgeRoleChoices | '%future added value';
  updatedAt: Scalars['DateTime'];
};

export type EdgeConnection = {
  __typename: 'EdgeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EdgeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Edge` and its cursor. */
export type EdgeEdge = {
  __typename: 'EdgeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Edge>;
};

export type ErrorType = {
  __typename: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/**
 *
 *     EventSource indicates what is triggering an evaluation of a rule.
 *
 */
export enum EventSource {
  Datapoint = 'datapoint',
  ManualEntry = 'manual_entry',
  Sql = 'sql'
}

export type ExternalApp = Node & {
  __typename: 'ExternalApp';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ExternalAppConnection = {
  __typename: 'ExternalAppConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalAppEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExternalApp` and its cursor. */
export type ExternalAppEdge = {
  __typename: 'ExternalAppEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ExternalApp>;
};

export type Fleet = Node & {
  __typename: 'Fleet';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

export type Group = {
  __typename: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: PermissionConnection;
};


export type GroupPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codename?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Heatmap = Node & {
  __typename: 'Heatmap';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  layers: Array<HeatmapLayer>;
  name: Scalars['String'];
  rootLayer?: Maybe<HeatmapLayer>;
  updatedAt: Scalars['DateTime'];
};

export type HeatmapColumn = {
  __typename: 'HeatmapColumn';
  data?: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
  highThreshold?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Indicates if the threshold should be evaluated in a waywhere lower is better (inverse=true). Defaults to false,where higher is better. */
  inverse?: Maybe<Scalars['Boolean']>;
  lowThreshold?: Maybe<Scalars['Float']>;
  popup?: Maybe<HeatmapColumnPopup>;
  position: Scalars['Int'];
  /** Property from the data objects to be used as a way to matchan entry with a specific row */
  row?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Property from the data objects to be used as `value` */
  value?: Maybe<Scalars['String']>;
};

export type HeatmapColumnPopup = Node & {
  __typename: 'HeatmapColumnPopup';
  data?: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
  highThreshold?: Maybe<Scalars['Float']>;
  /** The ID of the object */
  id: Scalars['ID'];
  invertedThreshold: Scalars['Boolean'];
  lowThreshold?: Maybe<Scalars['Float']>;
};

export type HeatmapConnection = {
  __typename: 'HeatmapConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HeatmapEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Heatmap` and its cursor. */
export type HeatmapEdge = {
  __typename: 'HeatmapEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Heatmap>;
};

export type HeatmapLayer = {
  __typename: 'HeatmapLayer';
  /** List of children layers that can be accessed */
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  columns?: Maybe<Array<Maybe<HeatmapColumn>>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Name of the attribute on the row entries to be used as identifier */
  rowId?: Maybe<Scalars['String']>;
  /** Name of the attribute on the row entries to be used as a label. */
  rowLabel?: Maybe<Scalars['String']>;
  /** List of rows to be used as the heatmap domain */
  rows?: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
  updatedAt: Scalars['DateTime'];
};

export type HeatmapRender = {
  __typename: 'HeatmapRender';
  heatmap?: Maybe<Heatmap>;
  layer?: Maybe<HeatmapLayer>;
};

export type InvokeDataReportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type InvokeDataReportPayload = {
  __typename: 'InvokeDataReportPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  snapshot?: Maybe<DataReportSnapshot>;
};

export type LimitOffsetPagination = {
  limit: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
};

export type LoginMutation = {
  __typename: 'LoginMutation';
  token?: Maybe<Token>;
  user?: Maybe<User>;
};

export type Mutation = {
  __typename: 'Mutation';
  _debug?: Maybe<DjangoDebug>;
  /** Assigns a specific role (group) to a specific user */
  assignRole?: Maybe<AssignRoleMutationPayload>;
  createAsset?: Maybe<CreateAssetMutationPayload>;
  /** Creates a data report */
  createDataReport?: Maybe<CreateDataReportMutationPayload>;
  /** Creates a new Task as defined on the django backend in tasks.models.Task */
  createTask?: Maybe<CreateTaskMutationPayload>;
  createTaskComment?: Maybe<CreateTaskCommentMutationPayload>;
  /** deletes a relationship between two assets (non bi-directional) */
  deleteAssetRelation?: Maybe<DeleteRelatedAssetMutationPayload>;
  deleteDataReport?: Maybe<DeleteDataReportPayload>;
  deleteTask?: Maybe<DeleteTaskMutationPayload>;
  deleteTaskComment?: Maybe<DeleteTaskCommentMutationPayload>;
  dissociateRole?: Maybe<DissociateRoleMutationPayload>;
  invokeDataReport?: Maybe<InvokeDataReportPayload>;
  login?: Maybe<LoginMutation>;
  patchAsset?: Maybe<PatchAssetDataMutationPayload>;
  /**
   * Updates a Task as defined on the django backend in tasks.models.Task
   * This performas a partial update of the task, meaning that only the field specified will be updated
   * If the task does not exist, this will simply throw an error to the GraphQL client
   */
  patchTask?: Maybe<PatchTaskMutationPayload>;
  /** Adds a rule or modifies one if an existing id is provided */
  putAlertRule?: Maybe<PutAlertRulePayload>;
  putOrganization?: Maybe<PutOrganizationPayload>;
  putRole?: Maybe<PutRoleMutationPayload>;
  putTaskTemplate?: Maybe<PutTaskTemplateMutationPayload>;
  putUser?: Maybe<PutUserPayload>;
  raiseAlert?: Maybe<RaiseAlertPayload>;
  /** relates an asset to another asset (non bi-directional) */
  relateAsset?: Maybe<RelateAssetMutationPayload>;
  /** relates an asset to many other assets (non-bi-directional) */
  relateAssets?: Maybe<RelateAssetsMutationPayload>;
  /** Removes the scheduling for a data report */
  removeDataReportSnapshotSchedule?: Maybe<RemoveDataReportSnapshotSchedulePayload>;
  resolveAlerts?: Maybe<ResolveAlertsPayload>;
  /** Enables scheduling for a specific data report. */
  scheduleDataReportSnapshot?: Maybe<ScheduleDataReportSnapshotPayload>;
  setUserOrganization?: Maybe<SetUserOrganizationPayload>;
  updateAsset?: Maybe<UpdateAssetMutationPayload>;
  updateAssetDetailsLayoutSchema?: Maybe<UpdateAssetDetailsLayoutMutationPayload>;
  updateAssetKind?: Maybe<UpdateAssetKindMutationPayload>;
  updateAssetPresentationTableSchema?: Maybe<UpdateAssetPresentationTableSchemaPayload>;
  updateDataReport?: Maybe<UpdateDataReportMutationPayload>;
  updateSchema?: Maybe<UpdateAssetSchemaMutationPayload>;
  /**
   * Updates a Task as defined on the django backend in tasks.models.Task
   * This performas a full update of the task, meaning that all fields will be overwritten
   * If the task does not exist, this will simply throw an error to the GraphQL client
   */
  updateTask?: Maybe<UpdateTaskMutationPayload>;
};


export type MutationAssignRoleArgs = {
  input: AssignRoleMutationInput;
};


export type MutationCreateAssetArgs = {
  input: CreateAssetMutationInput;
};


export type MutationCreateDataReportArgs = {
  input: CreateDataReportMutationInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskMutationInput;
};


export type MutationCreateTaskCommentArgs = {
  input: CreateTaskCommentMutationInput;
};


export type MutationDeleteAssetRelationArgs = {
  input: DeleteRelatedAssetMutationInput;
};


export type MutationDeleteDataReportArgs = {
  input: DeleteDataReportInput;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTaskMutationInput;
};


export type MutationDeleteTaskCommentArgs = {
  input: DeleteTaskCommentMutationInput;
};


export type MutationDissociateRoleArgs = {
  input: DissociateRoleMutationInput;
};


export type MutationInvokeDataReportArgs = {
  input: InvokeDataReportInput;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationPatchAssetArgs = {
  input: PatchAssetDataMutationInput;
};


export type MutationPatchTaskArgs = {
  input: PatchTaskMutationInput;
};


export type MutationPutAlertRuleArgs = {
  input: PutAlertRuleInput;
};


export type MutationPutOrganizationArgs = {
  input: PutOrganizationInput;
};


export type MutationPutRoleArgs = {
  input: PutRoleMutationInput;
};


export type MutationPutTaskTemplateArgs = {
  input: PutTaskTemplateMutationInput;
};


export type MutationPutUserArgs = {
  input: PutUserInput;
};


export type MutationRaiseAlertArgs = {
  input: RaiseAlertInput;
};


export type MutationRelateAssetArgs = {
  input: RelateAssetMutationInput;
};


export type MutationRelateAssetsArgs = {
  input: RelateAssetsMutationInput;
};


export type MutationRemoveDataReportSnapshotScheduleArgs = {
  input: RemoveDataReportSnapshotScheduleInput;
};


export type MutationResolveAlertsArgs = {
  input: ResolveAlertsInput;
};


export type MutationScheduleDataReportSnapshotArgs = {
  input: ScheduleDataReportSnapshotInput;
};


export type MutationSetUserOrganizationArgs = {
  input: SetUserOrganizationInput;
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetMutationInput;
};


export type MutationUpdateAssetDetailsLayoutSchemaArgs = {
  input: UpdateAssetDetailsLayoutMutationInput;
};


export type MutationUpdateAssetKindArgs = {
  input: UpdateAssetKindMutationInput;
};


export type MutationUpdateAssetPresentationTableSchemaArgs = {
  input: UpdateAssetPresentationTableSchemaInput;
};


export type MutationUpdateDataReportArgs = {
  input: UpdateDataReportMutationInput;
};


export type MutationUpdateSchemaArgs = {
  input: UpdateAssetSchemaMutationInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskMutationInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type Organization = Node & {
  __typename: 'Organization';
  ancestors?: Maybe<Array<Maybe<Organization>>>;
  children: OrganizationConnection;
  description: Scalars['String'];
  fleet: Fleet;
  hierarchy?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Organization>;
  userCount?: Maybe<Scalars['Int']>;
};


export type OrganizationChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentOnly?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationConnection = {
  __typename: 'OrganizationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrganizationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Organization` and its cursor. */
export type OrganizationEdge = {
  __typename: 'OrganizationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Organization>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PatchAssetDataMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  field: Scalars['GenericScalar'];
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['GenericScalar']>;
};

export type PatchAssetDataMutationPayload = {
  __typename: 'PatchAssetDataMutationPayload';
  asset?: Maybe<BaseAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type PatchTaskMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  field: Scalars['GenericScalar'];
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['GenericScalar']>;
};

/**
 * Updates a Task as defined on the django backend in tasks.models.Task
 * This performas a partial update of the task, meaning that only the field specified will be updated
 * If the task does not exist, this will simply throw an error to the GraphQL client
 */
export type PatchTaskMutationPayload = {
  __typename: 'PatchTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

export type Permission = Node & {
  __typename: 'Permission';
  codename: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PermissionConnection = {
  __typename: 'PermissionConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Permission` and its cursor. */
export type PermissionEdge = {
  __typename: 'PermissionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Permission>;
};

/**
 *
 *     Priority is used to indicate the relevance/urgency of an alert
 *
 */
export enum Priority {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type PutAlertRuleInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  defaultPriority?: InputMaybe<Priority | '%future added value'>;
  definitionObject?: InputMaybe<Scalars['GenericScalar']>;
  description?: InputMaybe<Scalars['String']>;
  eventSource: EventSource | '%future added value';
  fleet?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  raiseAlertOnError?: InputMaybe<Scalars['Boolean']>;
  ruleKind: Scalars['String'];
  taskTemplate?: InputMaybe<Scalars['ID']>;
  taskTemplateData?: InputMaybe<Scalars['GenericScalar']>;
};

/** Adds a rule or modifies one if an existing id is provided */
export type PutAlertRulePayload = {
  __typename: 'PutAlertRulePayload';
  alertRule?: Maybe<AlertRule>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ErrorType>;
};

export type PutOrganizationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  fleet?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  parent?: InputMaybe<Scalars['ID']>;
};

export type PutOrganizationPayload = {
  __typename: 'PutOrganizationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ErrorType>;
  organization?: Maybe<Organization>;
};

export type PutRoleMutationInput = {
  appModuleConfigurations?: InputMaybe<Array<InputMaybe<RoleAppModuleConfiguration>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PutRoleMutationPayload = {
  __typename: 'PutRoleMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  role?: Maybe<Role>;
};

export type PutTaskTemplateMutationInput = {
  alertRule?: InputMaybe<Scalars['ID']>;
  asset?: InputMaybe<Scalars['ID']>;
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  author?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PutTaskTemplateMutationPayload = {
  __typename: 'PutTaskTemplateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ErrorType>;
  taskTemplate?: Maybe<TaskTemplate>;
};

export type PutUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fleet?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type PutUserPayload = {
  __typename: 'PutUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ErrorType>;
  user?: Maybe<User>;
};

export type Query = {
  __typename: 'Query';
  _debug?: Maybe<DjangoDebug>;
  alert?: Maybe<Alert>;
  alertCounter?: Maybe<AlertCounter>;
  alertRule?: Maybe<AlertRule>;
  alertRules?: Maybe<AlertRuleConnection>;
  alerts?: Maybe<AlertConnection>;
  allPresentations?: Maybe<AssetPresentationConnection>;
  allReports?: Maybe<ReportConnection>;
  appModules?: Maybe<AppModuleConnection>;
  asset?: Maybe<BaseAsset>;
  assetDetailsLayout?: Maybe<Scalars['GenericScalar']>;
  assetFilterValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  assetKind?: Maybe<AssetKind>;
  assetKinds?: Maybe<AssetKindConnection>;
  assetSchema?: Maybe<AssetSchema>;
  assetSchemas?: Maybe<AssetSchemaConnection>;
  assets?: Maybe<BaseAssetConnection>;
  chartDashboard?: Maybe<ChartDashboard>;
  chartDashboardCardEntries?: Maybe<ChartDashboardCardEntryConnection>;
  chartDashboardCards?: Maybe<ChartDashboardCardConnection>;
  chartDashboards?: Maybe<ChartDashboardConnection>;
  dataReport?: Maybe<DataReport>;
  dataReportSnapshot?: Maybe<DataReportSnapshot>;
  dataReportSnapshotRows?: Maybe<DataReportSnapshotRowConnection>;
  dataReportSnapshots?: Maybe<DataReportSnapshotConnection>;
  dataReports?: Maybe<DataReportConnection>;
  datapoint?: Maybe<DataPoint>;
  datapoints?: Maybe<DataPointConnection>;
  externalApp?: Maybe<ExternalApp>;
  externalApps?: Maybe<ExternalAppConnection>;
  heatmapColumnPopup?: Maybe<HeatmapColumnPopup>;
  heatmaps?: Maybe<HeatmapConnection>;
  me?: Maybe<User>;
  organization?: Maybe<Organization>;
  organizationTree?: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
  organizations?: Maybe<OrganizationConnection>;
  permissions?: Maybe<PermissionConnection>;
  presentation?: Maybe<AssetPresentation>;
  renderHeatmap?: Maybe<HeatmapRender>;
  report?: Maybe<Report>;
  reportFilterValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportRows?: Maybe<ReportRowConnection>;
  role?: Maybe<Role>;
  roles?: Maybe<RoleConnection>;
  task?: Maybe<Task>;
  taskComment?: Maybe<TaskComment>;
  taskComments?: Maybe<TaskCommentConnection>;
  taskTemplates?: Maybe<TaskTemplateConnection>;
  tasks?: Maybe<TaskConnection>;
  user?: Maybe<User>;
  users?: Maybe<UserConnection>;
};


export type QueryAlertArgs = {
  id: Scalars['ID'];
};


export type QueryAlertRuleArgs = {
  id: Scalars['ID'];
};


export type QueryAlertRulesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  defaultPriority?: InputMaybe<Priority>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type QueryAlertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Priority>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryAllPresentationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAllReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAppModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetDetailsLayoutArgs = {
  assetKindId: Scalars['ID'];
};


export type QueryAssetFilterValuesArgs = {
  input?: InputMaybe<AssetFilterValuesParams>;
};


export type QueryAssetKindArgs = {
  id: Scalars['ID'];
};


export type QueryAssetKindsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasSchema?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetSchemaArgs = {
  id: Scalars['ID'];
};


export type QueryAssetSchemasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryChartDashboardArgs = {
  id: Scalars['ID'];
};


export type QueryChartDashboardCardEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  card?: InputMaybe<Scalars['ID']>;
  card_Dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryChartDashboardCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryChartDashboardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type QueryDataReportArgs = {
  id: Scalars['ID'];
};


export type QueryDataReportSnapshotArgs = {
  id: Scalars['ID'];
};


export type QueryDataReportSnapshotRowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['ID']>;
};


export type QueryDataReportSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<ReportSource>;
  sourceId?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
};


export type QueryDataReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryDatapointArgs = {
  id: Scalars['ID'];
};


export type QueryDatapointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['ID']>;
  timestamp_Range?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};


export type QueryExternalAppArgs = {
  id: Scalars['ID'];
};


export type QueryExternalAppsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};


export type QueryHeatmapColumnPopupArgs = {
  popupId: Scalars['ID'];
  queryParams?: InputMaybe<RenderHeatmapColumnPopupParams>;
};


export type QueryHeatmapsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codename?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPresentationArgs = {
  id: Scalars['ID'];
};


export type QueryRenderHeatmapArgs = {
  params?: InputMaybe<RenderHeatmapParams>;
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportFilterValuesArgs = {
  input?: InputMaybe<ReportFilterValuesParams>;
};


export type QueryReportRowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  report?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTaskCommentArgs = {
  id: Scalars['ID'];
};


export type QueryTaskCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryTaskTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type RaiseAlertInput = {
  assetData?: InputMaybe<Scalars['GenericScalar']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  eventData?: InputMaybe<Scalars['GenericScalar']>;
  ruleId: Scalars['ID'];
};

export type RaiseAlertPayload = {
  __typename: 'RaiseAlertPayload';
  alert?: Maybe<Alert>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type RelateAssetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ID'];
  targetId: Scalars['ID'];
};

/** relates an asset to another asset (non bi-directional) */
export type RelateAssetMutationPayload = {
  __typename: 'RelateAssetMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  relation?: Maybe<BaseAssetRelation>;
};

export type RelateAssetsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ID'];
  targetIds: Array<Scalars['ID']>;
};

/** relates an asset to many other assets (non-bi-directional) */
export type RelateAssetsMutationPayload = {
  __typename: 'RelateAssetsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  relations?: Maybe<Array<BaseAssetRelation>>;
};

export type RemoveDataReportSnapshotScheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataReportId: Scalars['ID'];
};

/** Removes the scheduling for a data report */
export type RemoveDataReportSnapshotSchedulePayload = {
  __typename: 'RemoveDataReportSnapshotSchedulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type RenderHeatmapColumnPopupParams = {
  /** A key/map object containing extra context for the columndata retrieving function.This can be used to share things such as the parent's layerrow/column selection that can be used to narrow data furtheron the next layer. */
  context?: InputMaybe<Scalars['GenericScalar']>;
  dateRange?: InputMaybe<DateRange>;
};

export type RenderHeatmapParams = {
  /** A key/map object containing extra context for the columndata retrieving function.This can be used to share things such as the parent's layerrow/column selection that can be used to narrow data furtheron the next layer. */
  context?: InputMaybe<Scalars['GenericScalar']>;
  dateRange?: InputMaybe<DateRange>;
  /** ID of the Heatmap to render */
  id: Scalars['ID'];
  /**
   *
   *         If specified, the path will be followed in order to render the appropriate layer
   *
   */
  path?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Report = Node & {
  __typename: 'Report';
  config?: Maybe<Scalars['GenericScalar']>;
  /** An object where the keys are the user-friendly labels and values are the dev friendly attribute names */
  fields?: Maybe<Scalars['GenericScalar']>;
  file: Scalars['String'];
  fileUri?: Maybe<Scalars['String']>;
  fleet?: Maybe<Fleet>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
};

export type ReportConnection = {
  __typename: 'ReportConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Report` and its cursor. */
export type ReportEdge = {
  __typename: 'ReportEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Report>;
};

export type ReportFilterValuesParams = {
  field?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  pagination?: InputMaybe<LimitOffsetPagination>;
  reportId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
};

export type ReportRow = Node & {
  __typename: 'ReportRow';
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  report: Report;
};

export type ReportRowConnection = {
  __typename: 'ReportRowConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportRowEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ReportRow` and its cursor. */
export type ReportRowEdge = {
  __typename: 'ReportRowEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ReportRow>;
};

/** An enumeration. */
export enum ReportSource {
  AiGenerated = 'ai_generated',
  Assets = 'assets',
  Csv = 'csv'
}

export type ResolveAlertsInput = {
  /** List of alert ids that will be marked as resolved */
  alertIds: Array<InputMaybe<Scalars['ID']>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type ResolveAlertsPayload = {
  __typename: 'ResolveAlertsPayload';
  alerts?: Maybe<Array<Maybe<Alert>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Role = Node & {
  __typename: 'Role';
  appModuleAccessConfigurations: AppModuleRoleConfigurationConnection;
  description?: Maybe<Scalars['String']>;
  fleet?: Maybe<Fleet>;
  group?: Maybe<Group>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type RoleAppModuleAccessConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RoleAppModuleConfiguration = {
  id: Scalars['String'];
  mode: AccessMode | '%future added value';
};

export type RoleConnection = {
  __typename: 'RoleConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RoleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Role` and its cursor. */
export type RoleEdge = {
  __typename: 'RoleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Role>;
};

export type ScheduleDataReportSnapshotInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataReportId: Scalars['ID'];
  schedulingTime?: InputMaybe<SchedulingTime | '%future added value'>;
};

/** Enables scheduling for a specific data report. */
export type ScheduleDataReportSnapshotPayload = {
  __typename: 'ScheduleDataReportSnapshotPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

/**
 *
 *     Enums used to indicate that a task must be scheduled in one
 *     of the following patterns.
 *
 *     The values themselves are used by celery-beat to identify
 *     specific previously-created cronjobs.
 *
 *     NOTE: the integer represents the id in the Database
 *
 *     usage example:
 *         CrontabSchedule.objects.filter(id=SchedulingTime.DAILY.value).first()
 *
 */
export enum SchedulingTime {
  Daily = 'DAILY',
  FifteenthOfMonth = 'FIFTEENTH_OF_MONTH',
  FirstOfMonth = 'FIRST_OF_MONTH',
  Friday = 'FRIDAY',
  Hourly = 'HOURLY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type SetUserOrganizationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type SetUserOrganizationPayload = {
  __typename: 'SetUserOrganizationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type Task = Node & {
  __typename: 'Task';
  alertRule?: Maybe<AlertRule>;
  asset?: Maybe<BaseAsset>;
  assignedTo?: Maybe<Array<Maybe<User>>>;
  author?: Maybe<User>;
  comments: TaskCommentConnection;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  status: TasksTaskStatusChoices | '%future added value';
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type TaskCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TaskComment = Node & {
  __typename: 'TaskComment';
  author: User;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  task?: Maybe<Task>;
  updatedAt: Scalars['DateTime'];
};

export type TaskCommentConnection = {
  __typename: 'TaskCommentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskCommentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TaskComment` and its cursor. */
export type TaskCommentEdge = {
  __typename: 'TaskCommentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TaskComment>;
};

export type TaskConnection = {
  __typename: 'TaskConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Task` and its cursor. */
export type TaskEdge = {
  __typename: 'TaskEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Task>;
};

export type TaskTemplate = Node & {
  __typename: 'TaskTemplate';
  alertRule?: Maybe<AlertRule>;
  alertruleSet: AlertRuleConnection;
  asset?: Maybe<BaseAsset>;
  assignedTo?: Maybe<Array<Maybe<User>>>;
  author?: Maybe<User>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  status: TasksTaskTemplateStatusChoices | '%future added value';
  tasks?: Maybe<Array<Maybe<Task>>>;
  title: Scalars['String'];
};


export type TaskTemplateAlertruleSetArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  defaultPriority?: InputMaybe<Priority>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type TaskTemplateConnection = {
  __typename: 'TaskTemplateConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskTemplateEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TaskTemplate` and its cursor. */
export type TaskTemplateEdge = {
  __typename: 'TaskTemplateEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TaskTemplate>;
};

/** An enumeration. */
export enum TasksTaskStatusChoices {
  /** Done */
  Done = 'DONE',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Open */
  Open = 'OPEN'
}

/** An enumeration. */
export enum TasksTaskTemplateStatusChoices {
  /** Done */
  Done = 'DONE',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Open */
  Open = 'OPEN'
}

export type Token = {
  __typename: 'Token';
  accessToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  expiresIn?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
};

export type UpdateAssetDetailsLayoutMutationInput = {
  assetKindId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['GenericScalar']>;
};

export type UpdateAssetDetailsLayoutMutationPayload = {
  __typename: 'UpdateAssetDetailsLayoutMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  layout?: Maybe<AssetDetailsLayout>;
};

export type UpdateAssetKindMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: Scalars['GenericScalar'];
  id: Scalars['ID'];
};

export type UpdateAssetKindMutationPayload = {
  __typename: 'UpdateAssetKindMutationPayload';
  assetKind?: Maybe<AssetKind>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type UpdateAssetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: Scalars['GenericScalar'];
  id: Scalars['ID'];
};

export type UpdateAssetMutationPayload = {
  __typename: 'UpdateAssetMutationPayload';
  asset?: Maybe<BaseAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type UpdateAssetPresentationTableSchemaInput = {
  assetKindId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['GenericScalar']>;
};

export type UpdateAssetPresentationTableSchemaPayload = {
  __typename: 'UpdateAssetPresentationTableSchemaPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  schema?: Maybe<Scalars['GenericScalar']>;
};

export type UpdateAssetSchemaMutationInput = {
  /** ID of the AssetKind which schema is going to be updated */
  assetKindId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Schema value */
  data: Scalars['GenericScalar'];
};

export type UpdateAssetSchemaMutationPayload = {
  __typename: 'UpdateAssetSchemaMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  kind?: Maybe<AssetKind>;
};

export type UpdateDataReportMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  id: Scalars['ID'];
  layout?: InputMaybe<Scalars['GenericScalar']>;
  name?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['GenericScalar']>;
  sort?: InputMaybe<Scalars['GenericScalar']>;
};

export type UpdateDataReportMutationPayload = {
  __typename: 'UpdateDataReportMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type UpdateTaskMutationInput = {
  asset?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/**
 * Updates a Task as defined on the django backend in tasks.models.Task
 * This performas a full update of the task, meaning that all fields will be overwritten
 * If the task does not exist, this will simply throw an error to the GraphQL client
 */
export type UpdateTaskMutationPayload = {
  __typename: 'UpdateTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

export type User = Node & {
  __typename: 'User';
  assignedOrganization?: Maybe<Array<Maybe<Organization>>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fleet: Fleet;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: Array<Group>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  lastName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  role?: Maybe<Role>;
  title?: Maybe<Scalars['String']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type UserConnection = {
  __typename: 'UserConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<User>;
};

export type PaginationFragment = { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type InvokeDataReportMutationVariables = Exact<{
  input: InvokeDataReportInput;
}>;


export type InvokeDataReportMutation = { __typename: 'Mutation', invokeDataReport?: { __typename: 'InvokeDataReportPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, snapshot?: { __typename: 'DataReportSnapshot', id: string, name: string, createdAt: any } | null } | null };

export type CreateDatareportMutationVariables = Exact<{
  input: CreateDataReportMutationInput;
}>;


export type CreateDatareportMutation = { __typename: 'Mutation', createDataReport?: { __typename: 'CreateDataReportMutationPayload', dataReport?: { __typename: 'DataReport', id: string } | null, errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null } | null };

export type ExternalAppsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ExternalAppsQuery = { __typename: 'Query', externalApps?: { __typename: 'ExternalAppConnection', pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'ExternalAppEdge', node?: { __typename: 'ExternalApp', id: string, name: string, url: string } | null } | null> } | null };

export type ExternalAppQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExternalAppQuery = { __typename: 'Query', externalApp?: { __typename: 'ExternalApp', id: string, url: string, name: string } | null };

export type DataReportsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type DataReportsQuery = { __typename: 'Query', dataReports?: { __typename: 'DataReportConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'DataReportEdge', node?: { __typename: 'DataReport', id: string, name: string, source?: ReportSource | null, sourceId: string, createdAt: any, updatedAt: any } | null } | null> } | null };

export type DataReportSnapshotsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type DataReportSnapshotsQuery = { __typename: 'Query', dataReportSnapshots?: { __typename: 'DataReportSnapshotConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'DataReportSnapshotEdge', node?: { __typename: 'DataReportSnapshot', id: string, name: string, source?: ReportSource | null, sourceId?: string | null, createdAt: any } | null } | null> } | null };

export const PaginationFragmentDoc = gql`
    fragment Pagination on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const InvokeDataReportDocument = gql`
    mutation InvokeDataReport($input: InvokeDataReportInput!) {
  invokeDataReport(input: $input) {
    errors {
      field
      messages
    }
    snapshot {
      id
      name
      createdAt
    }
  }
}
    `;
export type InvokeDataReportMutationFn = Apollo.MutationFunction<InvokeDataReportMutation, InvokeDataReportMutationVariables>;

/**
 * __useInvokeDataReportMutation__
 *
 * To run a mutation, you first call `useInvokeDataReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvokeDataReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invokeDataReportMutation, { data, loading, error }] = useInvokeDataReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvokeDataReportMutation(baseOptions?: Apollo.MutationHookOptions<InvokeDataReportMutation, InvokeDataReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvokeDataReportMutation, InvokeDataReportMutationVariables>(InvokeDataReportDocument, options);
      }
export type InvokeDataReportMutationHookResult = ReturnType<typeof useInvokeDataReportMutation>;
export type InvokeDataReportMutationResult = Apollo.MutationResult<InvokeDataReportMutation>;
export type InvokeDataReportMutationOptions = Apollo.BaseMutationOptions<InvokeDataReportMutation, InvokeDataReportMutationVariables>;
export const CreateDatareportDocument = gql`
    mutation CreateDatareport($input: CreateDataReportMutationInput!) {
  createDataReport(input: $input) {
    dataReport {
      id
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateDatareportMutationFn = Apollo.MutationFunction<CreateDatareportMutation, CreateDatareportMutationVariables>;

/**
 * __useCreateDatareportMutation__
 *
 * To run a mutation, you first call `useCreateDatareportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatareportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatareportMutation, { data, loading, error }] = useCreateDatareportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDatareportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDatareportMutation, CreateDatareportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDatareportMutation, CreateDatareportMutationVariables>(CreateDatareportDocument, options);
      }
export type CreateDatareportMutationHookResult = ReturnType<typeof useCreateDatareportMutation>;
export type CreateDatareportMutationResult = Apollo.MutationResult<CreateDatareportMutation>;
export type CreateDatareportMutationOptions = Apollo.BaseMutationOptions<CreateDatareportMutation, CreateDatareportMutationVariables>;
export const ExternalAppsDocument = gql`
    query ExternalApps($first: Int, $after: String) {
  externalApps(first: $first, after: $after) {
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        name
        url
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useExternalAppsQuery__
 *
 * To run a query within a React component, call `useExternalAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAppsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useExternalAppsQuery(baseOptions?: Apollo.QueryHookOptions<ExternalAppsQuery, ExternalAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalAppsQuery, ExternalAppsQueryVariables>(ExternalAppsDocument, options);
      }
export function useExternalAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalAppsQuery, ExternalAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalAppsQuery, ExternalAppsQueryVariables>(ExternalAppsDocument, options);
        }
export type ExternalAppsQueryHookResult = ReturnType<typeof useExternalAppsQuery>;
export type ExternalAppsLazyQueryHookResult = ReturnType<typeof useExternalAppsLazyQuery>;
export type ExternalAppsQueryResult = Apollo.QueryResult<ExternalAppsQuery, ExternalAppsQueryVariables>;
export const ExternalAppDocument = gql`
    query ExternalApp($id: ID!) {
  externalApp(id: $id) {
    id
    url
    name
  }
}
    `;

/**
 * __useExternalAppQuery__
 *
 * To run a query within a React component, call `useExternalAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExternalAppQuery(baseOptions: Apollo.QueryHookOptions<ExternalAppQuery, ExternalAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalAppQuery, ExternalAppQueryVariables>(ExternalAppDocument, options);
      }
export function useExternalAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalAppQuery, ExternalAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalAppQuery, ExternalAppQueryVariables>(ExternalAppDocument, options);
        }
export type ExternalAppQueryHookResult = ReturnType<typeof useExternalAppQuery>;
export type ExternalAppLazyQueryHookResult = ReturnType<typeof useExternalAppLazyQuery>;
export type ExternalAppQueryResult = Apollo.QueryResult<ExternalAppQuery, ExternalAppQueryVariables>;
export const DataReportsDocument = gql`
    query DataReports($first: Int, $after: String) {
  dataReports(first: $first, after: $after) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        name
        source
        sourceId
        createdAt
        updatedAt
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDataReportsQuery__
 *
 * To run a query within a React component, call `useDataReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDataReportsQuery(baseOptions?: Apollo.QueryHookOptions<DataReportsQuery, DataReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportsQuery, DataReportsQueryVariables>(DataReportsDocument, options);
      }
export function useDataReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportsQuery, DataReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportsQuery, DataReportsQueryVariables>(DataReportsDocument, options);
        }
export type DataReportsQueryHookResult = ReturnType<typeof useDataReportsQuery>;
export type DataReportsLazyQueryHookResult = ReturnType<typeof useDataReportsLazyQuery>;
export type DataReportsQueryResult = Apollo.QueryResult<DataReportsQuery, DataReportsQueryVariables>;
export const DataReportSnapshotsDocument = gql`
    query DataReportSnapshots($first: Int, $after: String) {
  dataReportSnapshots(first: $first, after: $after) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        name
        source
        sourceId
        createdAt
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDataReportSnapshotsQuery__
 *
 * To run a query within a React component, call `useDataReportSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportSnapshotsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDataReportSnapshotsQuery(baseOptions?: Apollo.QueryHookOptions<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>(DataReportSnapshotsDocument, options);
      }
export function useDataReportSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>(DataReportSnapshotsDocument, options);
        }
export type DataReportSnapshotsQueryHookResult = ReturnType<typeof useDataReportSnapshotsQuery>;
export type DataReportSnapshotsLazyQueryHookResult = ReturnType<typeof useDataReportSnapshotsLazyQuery>;
export type DataReportSnapshotsQueryResult = Apollo.QueryResult<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>;